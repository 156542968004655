<template>
<span>
  <div v-if="loading" class="text-white text-center">
    <transition name="fade">
      <div class="text-white text-center" :style="style">
        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top" style="height: 80px;">
          <img :src="$config.BASE_URL + '/lg.jpg'" width="107.5" height="43" />
        </nav>
        <h1 class="kstyle title justify-content-md-center" style="margin-top: 100px;">Loading Climate Policy and Regulatory Dashboard</h1>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
        <div style="font-size: 1.5em; margin-top: 100px;">loaded: {{count}} initiatives</div>
      </div>
    </transition>
  </div>
  <b-container fluid>
      <b-row class="mb-4" v-if="!loading" style="background: #1E49E2; padding: 0px; height: 57px;">
        <b-col style="background: #1E49E2; height: 57px;">
          <!-- <div style="background: #1E49E2; font-size: 16px; color: white; height: 57px;"> -->
          <b-navbar style="background: #1E49E2; height: 57px;">
            <b-navbar-nav>
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item :to="{ name: 'CPRD' }" exact exact-active-class="active">Home</b-nav-item>
                <b-nav-item :to="{ name: 'ImpactPoliciesDashboard' }" exact exact-active-class="active">Dashboard</b-nav-item>
                <b-nav-item :to="{ name: 'ImpactPoliciesTable' }" exact exact-active-class="active">Table</b-nav-item>
                <b-nav-item :to="{ name: 'ImpactPoliciesCountry', params: { country: countrySelected } }" :active="activeImpactPoliciesCountry">Country profile</b-nav-item>
                <b-nav-item :to="{ name: 'ImpactPoliciesCountryComparison', params: { country1: countrySelected, country2: countrySelected2 } }" :active="activeCountryComparison">Country comparison</b-nav-item>
                <b-nav-item :to="{ name: 'ImpactPolicies' }" exact exact-active-class="active">Policies</b-nav-item>
                <b-nav-item v-if="activeSinglePolicy" :active="activeSinglePolicy">Single policy</b-nav-item>
                <b-nav-item v-if="activeSingleInitiative" :active="activeSingleInitiative">Single initiative</b-nav-item>
                <b-nav-item :to="{ name: 'ImpactPoliciesNews' }" exact exact-active-class="active">News</b-nav-item>
                <b-nav-item :to="{ name: 'ImpactPoliciesDownload' }" exact-active-class="active" :active="activeDownload">Download</b-nav-item>
                <b-nav-item v-if="$config.BUILD === 'full'" :to="{ name: 'ImpactPoliciesDataStrats' }" exact exact-active-class="active">Data stratification</b-nav-item>
                <b-nav-item :to="{ name: 'CPRDHelp' }" exact exact-active-class="active">Help & Support</b-nav-item>
              </b-nav>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item @click="reload" v-b-popover.hover.top="'Reload data'">Reload <b-icon-arrow-clockwise v-if="!loadingBackground"></b-icon-arrow-clockwise><b-icon-arrow-clockwise animation="spin" v-if="loadingBackground"></b-icon-arrow-clockwise></b-nav-item>
              </b-nav>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="!loading && ready">
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
  </b-container>
</span>
</template>

<script>
import _ from 'lodash'
import PouchDB from 'pouchdb'

import { BIconArrowClockwise } from 'bootstrap-vue'

let dbPolicies = new PouchDB('policies')
let dbInitiativesPolicies = new PouchDB('initiativesPolicies')

export default {
  name: 'ImpactPolicies',
  components: {
    BIconArrowClockwise
  },
  computed: {
    activeCountryComparison: function () {
      let result = false
      if (this.$route.name === 'ImpactPoliciesCountryComparison') {
        result = true
      }
      return result
    },
    activeDownload: function () {
      let result = false
      if (this.$route.path.includes('download')) {
        result = true
      }
      return result
    },
    activeImpactPoliciesCountry: function () {
      let result = false
      if (this.$route.name === 'ImpactPoliciesCountry') {
        result = true
      }
      return result
    },
    activeSingleInitiative: function () {
      let result = false
      if (this.$route.name === 'ImpactPoliciesInitiative') {
        result = true
      }
      return result
    },
    activeSinglePolicy: function () {
      let result = false
      if (this.$route.name === 'ImpactPoliciesPolicy') {
        result = true
      }
      return result
    },
    countrySelected: {
      get () {
        return this.$store.state.countryCPRD1
      },
      set (payload) {
        this.$store.commit('setCountryCPRD1', payload)
      }
    },
    countrySelected2: {
      get () {
        return this.$store.state.countryCPRD2
      },
      set (payload) {
        this.$store.commit('setCountryCPRD2', payload)
      }
    },
    initiativesPolicies: {
      get () {
        return this.$store.state.initiativesPolicies
      },
      set (payload) {
        this.$store.commit('setInitiativesPolicies', payload)
      }
    },
    policies: {
      get () {
        return this.$store.state.policies
      },
      set (payload) {
        this.$store.commit('setPolicies', payload)
      }
    },
    rows: function () {
      return this.$store.state.policies
    },
    rowsShow: function () {
      return this.$store.getters.policiesShow
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Policies"
    this.$store.commit('setBrand', 'Climate Policy and Regulatory Dashboard')
    const image = _.sample(['fox', 'penguin', 'photographer', 'tent', 'wave', 'whale'])
    this.style = `background-image: url('/img/${image}.jpg'); background-repeat: no-repeat; background-size: cover; position: fixed; z-index: 10000; top: 66px; right: 0px; bottom: 0px; left: 0px;`
    await this.load()
  },
  data () {
    const data = {
      count: 0,
      loading: true,
      loadingBackground: false,
      ready: false,
      style: ''
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.ready = false
      let loadFromDb = false
      let reloadFromDb = false
      const infoDbPolicies = await dbPolicies.info()
      const infoDbInitiativesPolicies = await dbInitiativesPolicies.info()
      if (this.policies.length === 0) {
        if (infoDbPolicies.doc_count === 0) {
          loadFromDb = true
        } else {
          let policies = await dbPolicies.allDocs({ include_docs: true })
          policies = policies.rows.map(x => x.doc)
          this.policies = policies
          reloadFromDb = true
        }
      }
      if (this.initiativesPolicies.length === 0) {
        if (infoDbInitiativesPolicies.doc_count === 0) {
          loadFromDb = true
        } else {
          let initiativesPolicies = await dbInitiativesPolicies.allDocs({ include_docs: true })
          initiativesPolicies = initiativesPolicies.rows.map(x => x.doc)
          this.initiativesPolicies = initiativesPolicies
          reloadFromDb = true
        }
      }
      if (loadFromDb) {
        await Promise.all([this.loadPolicies(), this.loadRows()])
        this.loading = false
        this.ready = true
      } else if (reloadFromDb) {
        this.loading = false
        this.ready = true
        this.loadingBackground = true
        await Promise.all([this.loadPolicies(), this.loadRows()])
        this.ready = false
        this.loadingBackground = false
        this.ready = true
      } else {
        this.loading = false
        this.ready = true
      }
    },
    loadPolicies: async function () {
      try {
        this.policies = await this.$Amplify.API.get('cosmos', '/impact/policies/cpd')
        const info = await dbPolicies.info()
        if (info.doc_count > 0) {
          await dbPolicies.destroy()
          dbPolicies = null
          dbPolicies = new PouchDB('policies')
        }
        await dbPolicies.bulkDocs(this.policies)
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    loadRows: async function () {
      let rows = []
      try {
        const limit = 50
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/impact/initiatives-policies/limit/${limit}/offset/${offset}`)
          _.each(response, x => {
            if (x.impactpolicy?.taxonomy[0]?.name) {
              x.area = {
                name: x.impactpolicy.taxonomy[0].name,
                id: x.impactpolicy.taxonomy[0].id
              }
              x.area_name = x.impactpolicy.taxonomy[0].name
              x.policy_name = x.impactpolicy.name
            } else {
              x.area = {
                name: 'Undefined',
                id: 0
              }
              x.area_name = 'Undefined'
              x.policy_name = 'Undefined'
            }
          })
          const data = rows.concat(response)
          rows = data
          this.count = rows.length
          page++
          await this.$nextTick()
        } while (response.length === limit)
        this.initiativesPolicies = _.uniqBy(rows, 'id')
        const info = await dbInitiativesPolicies.info()
        if (info.doc_count > 0) {
          await dbInitiativesPolicies.destroy()
          dbInitiativesPolicies = null
          dbInitiativesPolicies = new PouchDB('initiativesPolicies')
        }
        await dbInitiativesPolicies.bulkDocs(this.initiativesPolicies)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    reload: async function () {
      this.loadingBackground = true
      this.ready = true
      try {
        await Promise.all([this.loadPolicies(), this.loadRows()])
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
      this.ready = false
      this.loadingBackground = false
      this.loading = false
      this.ready = true
    }
  }
}
</script>
<style scoped>
#top-menu-bar li a {
  color: white;
  padding-left: 15px;
  padding-right: 15px;
}
#top-menu-bar li .active {
  background: #00B8F5;
}
</style>
